import { Switch } from "@headlessui/react";
import { memo, useState } from "react";
import Dropdown from "components/common/Dropdown";
import { MdAdd, MdDelete } from "react-icons/md";
import { BsTagsFill } from "react-icons/bs";
import DropdownWithoutBorder from "components/common/DropdownWithoutBorder";
import { URL_TEAM_UPDATE_MEMBER, URL_TEAM_ASSIGN_DATA, URL_TEAM_DELETE_DATA } from "constants";
import { axiosPrivate } from "api/axios";
import useBase from "hooks/useBase";

let roles = [
    { value: "admin", label: "Admin" },
    { value: "editor", label: "Editor" },
    { value: "client", label: "Client" },
    { value: "supervisor", label: "Supervisor" },
    { value: "surveyor", label: "Surveyor" },
];

const Card = ({
    options,
    data,
    dispatch,
    openDelModal,
    handleChange
}) => {
    const [assignData, setAssignData] = useState(data?.assign_data?.length>0?true:false);
    const [active, setActive] = useState(data.is_active);
    const [offsite, setOffsite] = useState(data.offsite);
    const [value, setValue] = useState("");
    const [ques, setQues] = useState(options[0]);
    const [showError, setShowError] = useState(false);
    let def_val = roles[4];
    roles.forEach((o) => {
        if (o.value === data?.role) {
            def_val = o;
        }
    });
    const { proj } = useBase()

    const handleRoleChange = async (e) => {
        await axiosPrivate
            .post(`${URL_TEAM_UPDATE_MEMBER}/${proj?.id}`, {
                role: e.value,
                id: data?.id
            })
            .then((res) => {
                dispatch({ type: "updateMemLocally", data: res.data.data });
            })
            .catch((error) => { });
    };
    const handleAssign = async (e) => {
        if (!ques.value > 0) {
            setShowError(true)
        }
        else
            await axiosPrivate
                .post(`${URL_TEAM_ASSIGN_DATA}/${proj?.id}`, {
                    ques: ques.value,
                    member: data?.id,
                    value: value
                })
                .then((res) => {
                    dispatch({ type: "addDataLocally", data: { data: res.data.data, member_id: data.id } });
                })
                .catch((error) => { });
    };
    const handleDelete = async (id) => {

        await axiosPrivate
            .post(`${URL_TEAM_DELETE_DATA}/${proj?.id}`, {
                id: id, 
                member_id: data.id 
            })
            .then((res) => {
            })
            .catch((error) => { });
        dispatch({ type: "removeDataLocally", data: { id: id, member_id: data.id } });
    };
    return (
        <div className="card mb-4 h-auto">
            <div className="md:grid grid-cols-2 p-4 gap-2">
                <div className="flex gap-6 self-center mb-4 md:mb-0">
                    <div className=" rounded-full bg-red-200 my-auto p-2" onClick={() => openDelModal()} >
                        <MdDelete className="text-xl text-red-600" title="Remove Surveyor"/>
                    </div>
                    <div className="sm:flex gap-4">
                        <div className="max-w-[250px]">
                        <span className="flex">
                            <h6 className="font-bold truncate">
                            {data?.name ? data.name : "-"}</h6>
                                {data?.access_level <=5 && 
                                <BsTagsFill className={`ml-2 mt-1 text-lg ${assignData?"text-green-700":"text-slate-400"}`}
                                title={`${assignData?"Remove Assinged Data":"Assing Data"}`} onClick={()=>{
                                    setAssignData(prev=>{
                                        if(prev) {
                                            handleDelete(-1)
                                        }
                                        return !prev
                                    })
                                }
                                    }/>}
                            </span>
                            <div className="flex gap-4">
                            <h6 className="text-xxs sm:text-xs">{data?.phone ? data.phone : "-"}</h6> 
                            <h6 className="text-xxs sm:text-xs">|</h6> 
                            <h6 className={`text-xxs sm:text-xs ${!data?.version || data?.version == "Old"&&"text-red-400"}`}>App Version - {data?.version ? data.version : "Old"}</h6> 
                            </div>
                            </div>
                        <div>
                        <DropdownWithoutBorder opt={roles} def={def_val} handler={handleRoleChange} />
                        </div>
                    </div>

                </div>
                <div className="sm:flex gap-8 self-center justify-self-end text-md">
                    <div className="flex gap-2 mb-2 sm:mb-0">
                        <span className="font-semi-bold">Offsite Survey</span>
                        <Switch
                            checked={offsite}
                            onChange={() => {
                                handleChange(data?.id, "offsite", !offsite)
                                setOffsite((prev) => !prev);
                            }}
                            className={`${offsite ? "bg-slate-700" : "bg-slate-300"}
          relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                            <span
                                aria-hidden="true"
                                className={`${offsite ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                            />
                        </Switch>
                    </div>
                    <div className="flex gap-2">
                        <span className="font-semi-bold">Survey Access</span>
                        <Switch
                            checked={active}
                            onChange={() => {
                                handleChange(data?.id, "is_active", !active)
                                setActive((prev) => !prev);
                            }}
                            className={`${active ? "bg-slate-700" : "bg-slate-300"}
          relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                            <span
                                aria-hidden="true"
                                className={`${active ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                            />
                        </Switch>
                    </div>

                </div>
            </div>
            {data?.access_level <= 5 && assignData &&
                <div className="border-t p-4 flex flex-col gap-2">{data?.assign_data?.length < 3 &&
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-1">
                            <span className="input-text border border-r-0 mb-0">
                                Question
                            </span>
                            <Dropdown opt={options} def={options[0]} w={"w-full"} handler={(e) => setQues(e)} showError={showError}
                                showTex="Please select the question" />
                        </div>
                        <div className="flex flex-1">
                            <span className="input-text border border-r-0 mb-0">
                                Value
                            </span>
                            <input
                                className="input mb-1"
                                name="value"
                                type="text"
                                placeholder="Enter value here"
                                required
                                defaultValue={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </div>
                        <div className="bg-green-300 input-text border-none mb-0 px-2" onClick={handleAssign} >
                            <MdAdd className="text-xl text-green-700 " />
                        </div>
                    </div>
                }
                    {data?.assign_data?.map((d) => {
                        // <Data d={d} options={options} />

                        let def_ques_val = "None";
                        options.forEach((o) => {
                            if (o.value === d.ques) {
                                def_ques_val = o.label;
                            }
                        });
                        return <div className="flex gap-4" key={d.id}>
                            <div className="flex flex-1">
                                <span className="input-text border border-r-0 mb-0 ">
                                    Question
                                </span>
                                <span className="input  bg-gray-50 mb-0">{def_ques_val}</span>
                            </div>
                            <div className="flex flex-1">
                                <span className="input-text border border-r-0 mb-0">
                                    Value
                                </span>
                                <span className="input bg-gray-50 mb-0">{d.value}</span>
                            </div>
                            <div className="bg-red-200 input-text border-none mb-0 px-2" onClick={() => handleDelete(d.id)}>
                                <MdDelete className="text-xl text-red-600 " />
                            </div>
                        </div>
                    }

                    )}
                </div>
            }
        </div>
    )

};

function teamPropsAreEqual(prev, next) {
    let a = prev.data === next.data && prev.options === next.options;
    return a;
}
const MemoCard = memo(Card, teamPropsAreEqual);

export default MemoCard;
